define("discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _dButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ThumbnailSuggestionItem = _exports.default = (_class = (_class2 = class ThumbnailSuggestionItem extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selected", _descriptor, this);
      _initializerDefineProperty(this, "selectIcon", _descriptor2, this);
      _initializerDefineProperty(this, "selectLabel", _descriptor3, this);
    }
    toggleSelection(thumbnail1) {
      if (this.selected) {
        this.selectIcon = "far-circle";
        this.selectLabel = "discourse_ai.ai_helper.thumbnail_suggestions.select";
        this.selected = false;
        return this.args.removeSelection(thumbnail1);
      }
      this.selectIcon = "check-circle";
      this.selectLabel = "discourse_ai.ai_helper.thumbnail_suggestions.selected";
      this.selected = true;
      return this.args.addSelection(thumbnail1);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="ai-thumbnail-suggestions__item">
        <DButton
          class={{if this.selected "btn-primary" ""}}
          @icon={{this.selectIcon}}
          @label={{this.selectLabel}}
          @action={{this.toggleSelection}}
          @actionParam={{@thumbnail}}
        />
        <img
          src={{@thumbnail.url}}
          loading="lazy"
          width={{@thumbnail.thumbnail_width}}
          height={{@thumbnail.thumbnail_height}}
        />
      </div>
    
  */
  {
    "id": "AhCAkZ0p",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-thumbnail-suggestions__item\"],[12],[1,\"\\n      \"],[8,[32,0],[[16,0,[52,[30,0,[\"selected\"]],\"btn-primary\",\"\"]]],[[\"@icon\",\"@label\",\"@action\",\"@actionParam\"],[[30,0,[\"selectIcon\"]],[30,0,[\"selectLabel\"]],[30,0,[\"toggleSelection\"]],[30,1]]],null],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,1,[\"url\"]]],[14,\"loading\",\"lazy\"],[15,\"width\",[30,1,[\"thumbnail_width\"]]],[15,\"height\",[30,1,[\"thumbnail_height\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@thumbnail\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "far-circle";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "discourse_ai.ai_helper.thumbnail_suggestions.select";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelection"), _class.prototype)), _class);
});